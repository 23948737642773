@font-face {
  font-family: "PoppinsSemiBold";
  src: url("fonts/Poppins-SemiBold.ttf") format("truetype");
}


@font-face {
  font-family: "PoppinsRegular";
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: url("fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
}


body {
  /* el tamaño por defecto es 14px */
  font-size: 11px !important;
  font-family: 'RobotoRegular' !important;
}


.login {
  position: inherit;
  height: 100%;
  background: #000000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overscroll-behavior: contain;
}

.login .form-input-sm {
  width: 190px;
  margin: 0 auto;
}

/* FAQs login */

.faqs {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 40px;
  color: #FFF;
  font-size: 14px;
  padding: 0 10%;
}

.faqs .faq {
  text-align: center;
  margin: 0 25px;
  max-width: 320px;
}

.faqs a {
  text-decoration: none;
  color: #E60026;
}

.faqs img {
  width: 90px;
  margin-bottom: 20px;
}

.faqs h3 {
  font-weight: bold;
  font-size: 19px;
}

.subtext {
  margin-top: 10px;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
}

.wrap {
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  /* height:100%; */
}

.btn-salir link {
  color: #FFFFFF;
}

.login .alert-alpogo-new {
  width: 200px;
  margin-top: 20px;
  border: 1px solid #E54335;
  background-color: transparent;
  border-radius: 4px;
  color: #E54335;
  font-size: 11px;
}

.alert-alpogo-new a {
  font-weight: bold;
}

.alert-alpogo-new a:hover {
  color: #7c231b;
}

.alert-alpogo-new img {
  margin-right: 10px;
}

/* Alerta sesión iniciada (y cerrar sesión) */

.alert-session {
  max-width: 240px;
  margin-top: 20px;
  border: 1px solid #FFF !important;
  background-color: transparent;
  border-radius: 4px;
  color: #FFF;
  font-size: 11px;
}

.alert-session a {
  color: #E54335;
}

.alert-session a:hover {
  color: #E54335;
}

.btn-access {
  font-family: 'RobotoRegular';
  color: #FFF;
  text-transform: uppercase;
  padding: 9px 45px;
  background: #76BF41;
  border-radius: 4px;
  border-color: #76BF41;
}

.text-tickets a {
  text-decoration: none;
  color: #E60026;
}

.text-tickets a:hover {
  color: #E60059;
}

/* boton abandonar sala */

.logut-wrapper {
  margin: 10px 0 14px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 9;
}

.logout-link {
  font-size: 10px;
  background-color: transparent;
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
  border-radius: 4px;
  padding: 8px 10px;
}

.logout-link:hover {
  background-color: transparent;
  color: #E60026;
  text-decoration: none;
}

.card-body {
  padding: 1rem !important;
}

.practitioners.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  border: 0;
}

.menu {
  background-color: #00A0FF;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  font-size: 12px !important;
  z-index: 1;
}

.menu_reducido {
  background-color: #00A0FF;
  display: flex;
  flex-direction: column;
  width: 70px;
  font-size: 12px !important;
  z-index: 1;
}

img.img {
  width: 29px;
  height: auto;
  vertical-align: sub;
  padding-left: 7px;
}

.menu_reducido #menu {
  display: none;
}

.header-wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: #000000;
}

.header_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  min-height: 60px;
}

.chat-iframe {
  background: #000000;
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.chat-iframe iframe {
  width: 80%;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.logo-alpogo-live {
  margin-top: 15px;
  width: 200px;
}


div .title {
  color: #3A318E;
  font-family: 'RobotoMedium';
  font-size: 20px
}

.nav-link {
  color: white;
  padding-left: 10px;
}


.login .btn-primary {
  background-color: rgb(93, 91, 100) !important;
  border: 0px solid rgb(50, 50, 51) !important;
}


.btn-primary {
  background-color: #42AC6F !important;
  border: 0px solid #42AC6F !important;
  opacity: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}


.btn {
  font-size: 13px !important;
  border-radius: 5px !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
}


::placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(48, 49, 51) !important;
  opacity: 50% !important;
}



.div-main {
  width: 100%;
  border-radius: 15px;
  padding-bottom: 20px;
  border-color: #DDDDDD;
  border: 1px #DDDDDD;
  border-style: solid;
}

.td-font {
  color: #707070;
  font: Regular 16px Roboto;
  letter-spacing: 0;
  font-weight: 600
}

/* media queries para desktop */

@media (min-width: 768px) {
  .chat-iframe iframe {
    width: 100%;
    height: 400px;
  }
}

@media (min-width: 1024px) {

  .content {
    display: flex;
    justify-content: flex-start;
  }

  .chat {
    width: 27%;
    margin-left: 10px;
  }

  .chat-iframe {
    margin: 0;
  }

  .header {
    width: 100%;
  }

  .faqs {
    max-width: 1440px;
    margin: 40px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .faqs .faq {
    width: 33%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .faqs .faq p {
    text-align: center;
  }
}

/* media queries para desktop fullhd */

@media (min-width: 1440px) {

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 200px;
  }


}